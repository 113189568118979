import React, { useState, useEffect, useLocalStorage } from "react";
import FacebookLogin from "react-facebook-login";
import {
  Card,
  Image,
  Button,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { history } from "../../../history";
import "../../../asset/css/status.css";
import ResultScreen from "../result/result";
import InfoScreen from "../info/info";

function App(props) {
  const [login, setLogin] = useState(false);
  const [name, setName] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [isWebFirst, setIsWebFirst] = useState(null);
  const [isWebDone, setIsWebDone] = useState(null);
  const [isFbDone, setIsFbDone] = useState(null);

  const [finished, setFinised] = useState(false); // debug to true

  const [dassScore, setDassScore] = useState(999);
  const [dassLevel, setDassLevel] = useState("");

  const [tmhqScore, setTmhqScore] = useState(999);
  const [tmhqLevel, setTmhqLevel] = useState("");

  const [dassDScore, setDassDScore] = useState(999);
  const [dassDLevel, setDassDLevel] = useState("");

  const [dassAScore, setDassAScore] = useState(999);
  const [dassALevel, setDassALevel] = useState("");
  
  const [dassSScore, setDassSScore] = useState(999);
  const [dassSLevel, setDassSLevel] = useState("");

  const [userQuery, setUserQuery] = useState(null);

  // Run once
  useEffect(() => {
    console.log('useEffect (start)');
    // query process
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let u = params.get("u");
    let d = params.get("d");

    if (u) {
      setUserQuery(u)
    }

    if (d == 'web') {
      let nameFromStorage = localStorage.getItem('name')
      if (nameFromStorage != null) {
        console.log('forwarded from web');
        props.database
          .ref('/users/' + nameFromStorage.split(" ").join('_'))
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) { // if name from query match existing user
              setName(nameFromStorage)
              setLogin(true)
            } else {
              history.push('/status')
            }
          })
      }
    }

  }, []);

  useEffect(() => {
      if (userQuery) {
      console.log('useEffect (uQuery)');
      if (!login) {
        console.log('not logged in, set fb done on db');
        props.database
          .ref('/users/' + userQuery)
          .once('value')
          .then(snapshot => {
            if (snapshot.exists()) { // if name from query match existing user
              props.database
                .ref('/users/' + userQuery)
                .update({
                  isFbDone: true
                })
                .then(() => {
                  setName(userQuery.split('_').join(' '))
                  localStorage.setItem('name', userQuery.split('_').join(' '))
                  setLogin(true)
                })
            } else {
              history.push('/status')
            }
          })
      } else {
        console.log('already logged in, do nothing');
      }
    }
  }, [userQuery])

  useEffect(() => {
    console.log('useEffect (login)');
    updateStateFromDb()
  }, [login]);

  useEffect(() => {
    if (isWebDone && isFbDone) {
      _handleFinished()
    }
  }, [isFbDone, isWebDone]);


  const updateStateFromDb = () => {
    console.log("updating state", login, name);
    if (login) {
      return props.database
        .ref("/users/" + name.split(" ").join("_"))
        .once("value")
        .then((snapshot) => {
          setIsWebFirst(snapshot.val().isWebFirst);
          setIsWebDone(snapshot.val().isWebDone);
          setIsFbDone(snapshot.val().isFbDone);
          return true;
        });
    }
  };

  const _handleFinished = () => {
    // calculate score
    /*
    let dass = JSON.parse(localStorage.getItem('dass'))
    let tmhq = JSON.parse(localStorage.getItem('tmhq'))
    */
    props.database
      .ref("/users/" + name.split(" ").join("_"))
      .once("value")
      .then((snapshot) => {
        let dass = snapshot.val().dass;
        let tmhq = snapshot.val().tmhq;

        // ------------------------------------- DASS
        let totalDass = 0;
        dass.map((q, index) => {
          totalDass += q;
        });

        let dassStressLevel;
        if (totalDass <= 7) {
          dassStressLevel = "ปกติ";
        } else if (totalDass <= 9) {
          dassStressLevel = "เล็กน้อย";
        } else if (totalDass <= 12) {
          dassStressLevel = "ปานกลาง";
        } else if (totalDass <= 16) {
          dassStressLevel = "รุนแรง";
        } else {
          dassStressLevel = "รุนแรงมาก";
        }

        let dPosition = [2, 4, 9, 12, 15, 16, 20];
        let aPosition = [1, 3, 6, 8, 14, 18, 19];
        let sPosition = [0, 5, 7, 10, 11, 13, 17];

        let dScore = 0;
        let aScore = 0;
        let sScore = 0;

        dass.map((q, index) => {
          if (dPosition.includes(index)) {
            dScore += q;
          } else if (aPosition.includes(index)) {
            aScore += q;
          } else if (sPosition.includes(index)) {
            sScore += q;
          }
        })

        let dLevel;
        if (dScore <= 4) {
          dLevel = "ปกติ"
        } else if (dScore <= 6) {
          dLevel = "เล็กน้อย"
        } else if (dScore <= 10) {
          dLevel = "ปานกลาง"
        } else if (dScore <= 13) {
          dLevel = "รุนแรง"
        } else {
          dLevel = "รุนแรงมาก"
        }

        let aLevel;
        if (aScore <= 3) {
          aLevel = "ปกติ"
        } else if (aScore <= 5) {
          aLevel = "เล็กน้อย"
        } else if (aScore <= 7) {
          aLevel = "ปานกลาง"
        } else if (aScore <= 9) {
          aLevel = "รุนแรง"
        } else {
          aLevel = "รุนแรงมาก"
        }

        let sLevel;
        if (sScore <= 7) {
          sLevel = "ปกติ"
        } else if (sScore <= 9) {
          sLevel = "เล็กน้อย"
        } else if (sScore <= 12) {
          sLevel = "ปานกลาง"
        } else if (sScore <= 16) {
          sLevel = "รุนแรง"
        } else {
          sLevel = "รุนแรงมาก"
        }

        // ------------------------------------- TMHQ
        let totalTmhq = 0;
        tmhq.map((q, index) => {
          totalTmhq += q;
        });

        let panic;
        if (totalTmhq <= 40) {
          panic = "ต่ำกว่าเกณฑ์ปกติ";
        } else if (totalTmhq <= 65) {
          panic = "ปกติ";
        } else {
          panic = "สูงกว่าเกณฑ์ปกติ";
        }

        setDassScore(totalDass);
        setDassLevel(dassStressLevel);
        setTmhqLevel(panic);
        setTmhqScore(totalTmhq);
        setDassDScore(dScore);
        setDassDLevel(dLevel);
        setDassAScore(aScore);
        setDassALevel(aLevel);
        setDassSScore(sScore);
        setDassSLevel(sLevel);
        setFinised(true);

        localStorage.clear()
      });
  };

  const responseFacebook = (response) => {
    console.log(response);

    let u_name_array = response.name.split(" ");
    let u_name = u_name_array[0] + " " + u_name_array[u_name_array.length - 1];
    let u_name_no_space = u_name.split(" ").join("_");

    localStorage.setItem("name", u_name);
    setName(u_name);

    if (response.accessToken) {
      props.database
        .ref("/users/" + u_name_no_space)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            setLogin(true)
          } else {
            randomSurvey(u_name_no_space);
            setLogin(true);
          }
        })
    } else {
      setLogin(false);
    }

  };

  const randomSurvey = (u_name_no_space) => {
    var now = new Date();
    if (now.getTime() % 2 == 0) {
      console.log(now.getTime(), "web first");
      props.database
        .ref("/users/" + u_name_no_space)
        .update({
          isWebFirst: true,
          isFbDone: false,
          isWebDone: false,
        })
        .then(() => {
          console.log("generated web/fb first on db");
        });
    } else {
      console.log(now.getTime(), "fb first");
      props.database
        .ref("/users/" + u_name_no_space)
        .update({
          isWebFirst: false,
          isFbDone: false,
          isWebDone: false,
        })
        .then(() => {
          console.log("generated web/fb first on db");
        });
    }
  };

  const onLogout = () => {
    window.FB.logout(() => {
      localStorage.clear();
      document.cookie = "c_user= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      setLogin(false);
      console.log("logged out");
    });
  };

  if (finished && login) {
    return (
      <React.Fragment>
        <ResultScreen
          database={props.database}
          dassScore={dassScore}
          dassLevel={dassLevel}
          tmhqScore={tmhqScore}
          tmhqLevel={tmhqLevel}
          dassDScore={dassDScore}
          dassDLevel={dassDLevel}
          dassAScore={dassAScore}
          dassALevel={dassALevel}
          dassSScore={dassSScore}
          dassSLevel={dassSLevel}
          name={name}
          onLogout={onLogout}
        />
        <InfoScreen />
      </React.Fragment>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header className="text-left">
              <h4>
                {login ? (
                  <FiCheckCircle color="#00f57f" />
                ) : (
                  <FiCircle color="#f72b2b" />
                )}{" "}
                ขั้นตอนที่ 1 : เข้าสู่ระบบด้วย Facebook
              </h4>
            </Card.Header>

            {login && (
              <Card.Body id="font1">
                <Card.Text>สวัสดีค่ะ คุณ {name}</Card.Text>
                <Button
                  variant="secondary"
                  id="font1"
                  className="rounded-pill"
                  onClick={(e) => {
                    e.preventDefault();
                    onLogout();
                  }}
                >
                  หรือคลิกที่นี่หากไม่ใช่คุณ
                </Button>
              </Card.Body>
            )}
            {!login && (
              <Card.Body>
                <FacebookLogin
                  appId="634544140535884"
                  autoLoad={false}
                  fields="name"
                  textButton="เข้าสู่ระบบด้วย Facebook"
                  language="th_TH"
                  scope="public_profile"
                  callback={responseFacebook}
                  disableMobileRedirect={true}
                  icon="fa-facebook"
                />
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {!login ? (
            <></>
          ) : isWebFirst ? (
            <Card id="font1">
              <Card.Header className="text-left">
                <h4>
                  {isWebDone ? (
                    <FiCheckCircle color="#00f57f" />
                  ) : (
                    <FiCircle color="#f72b2b" />
                  )}{" "}
                  ขั้นตอนที่ 2 : ประเมินสุขภาพจิตด้วยการตอบแบบสอบถาม
                </h4>
              </Card.Header>
              <Card.Body id="font1">
                <Button
                  id="font1"
                  disabled={isWebDone ? true : false}
                  className="rounded-pill"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/dass");
                  }}
                >
                  ตอบแบบสอบถาม
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card id="font1">
              <Card.Header className="text-left">
                <h4>
                  {isFbDone ? (
                    <FiCheckCircle color="#00f57f" />
                  ) : (
                    <FiCircle color="#f72b2b" />
                  )}{" "}
                  ขั้นตอนที่ 2 : ประเมินสุขภาพจิตกับแชทบอท
                </h4>
              </Card.Header>
              <Card.Body>
                <Button
                  disabled={isFbDone ? true : false}
                  className="rounded-pill"
                  href="https://m.me/101388542170495"
                  target="_blank"
                >
                  ประเมินกับแชทบอท
                </Button>
              </Card.Body>
            </Card>
          )}
          {!login ? (
            <></>
          ) : isWebFirst ? (
            <Card>
              <Card.Header className="text-left">
                <h4>
                  {isFbDone ? (
                    <FiCheckCircle color="#00f57f" />
                  ) : (
                    <FiCircle color="#f72b2b" />
                  )}{" "}
                  ขั้นตอนที่ 3 : ประเมินสุขภาพจิตกับแชทบอท
                </h4>
              </Card.Header>
              <Card.Body>
                <Button
                  disabled={!isWebDone ? true : isFbDone ? true : false}
                  className="rounded-pill"
                  href="https://m.me/101388542170495"
                  target="_blank"
                  id="font1"
                >
                  ประเมินกับแชทบอท
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Header className="text-left">
                <h4>
                  {isWebDone ? (
                    <FiCheckCircle color="#00f57f" />
                  ) : (
                    <FiCircle color="#f72b2b" />
                  )}{" "}
                  ขั้นตอนที่ 3 : ประเมินสุขภาพจิตด้วยการตอบแบบสอบถาม
                </h4>
              </Card.Header>
              <Card.Body id="font1">
                <Button
                  id="font1"
                  disabled={!isFbDone || isWebDone ? true : false}
                  className="rounded-pill"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/dass");
                  }}
                >
                  ตอบแบบสอบถาม
                </Button>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
