import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import{ BreakpointProvider } from 'react-socks';

// page
import Home from './views/pages/home/home'
import Consent from './views/pages/consent/consent'
import NavigationBar from './layout/navbar/NavigationBar'
import TMHQ from './views/pages/test/tmhq'
import DASS from './views/pages/test/dass'
import Status from './views/pages/status/Status'
import { useMediaQuery } from 'react-responsive'
import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyD7_uI04SRqzosQHDzqqiQpbihghPNEWco",
    authDomain: "psyjai-survey.firebaseapp.com",
    databaseURL: "https://psyjai-survey-mh.asia-southeast1.firebasedatabase.app/",
    projectId: "psyjai-survey",
    storageBucket: "psyjai-survey.appspot.com",
    messagingSenderId: "296999770756",
    appId: "1:296999770756:web:aa5234c08747b7c06680d1",
    measurementId: "G-0CSWWW4TBZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
const database = firebase.database();



export default class AppRouter extends React.Component {
    render() {
      return (

        <Router>
        <NavigationBar/>
          <div>
            <Switch>\
                <Route path="/consent">
                  <Consent database={database}/>
                </Route>
                <Route path="/tmhq">
                  <TMHQ database={database}/>
                </Route>
                <Route path="/dass">
                  <DASS database={database}/>
                </Route>
                <Route path="/status">
                  <Status database={database}/>
                </Route>
                <Route path="/">
                  <Home database={database}/>
                </Route>
            </Switch>
          </div>
        </Router>

      );
    }
  }
