import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import '../../../../asset/css/test.css'
import { AiOutlineClose } from 'react-icons/ai'
import { ModalTitle } from "react-bootstrap";
import "../../../../asset/css/test.css";
class dassModals extends React.Component{
    render(){
         console.log(this.props.isOpen)
        return(
            <div>
                 <Modal 
                 isOpen={this.props.isOpen} 
                 toggle={this.props.toggle}
                 className={this.props.className}
                 >
                <ModalHeader toggle={this.props.toggle} className="border-0" >    
                </ModalHeader>
                 <ModalBody className="mx-4 text-justify">
                 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>โปรดอ่านข้อความแต่ละข้อและวงกลมหมายเลข 0, 1, 2 หรือ 3 ที่ระบุข้อความได้ตรงกับท่านมากสุดในช่วง 2 สัปดาห์ที่ผ่านมา
                 ไม่มีคำตอบที่ถูกหรือคำตอบที่ผิด ท่านไม่ควรใช้เวลามากนักในแต่ละข้อความ
                 <br/><br/>เกณฑ์การประเมินมีดังนี้:<br/>
                 <p id="paddingfont">0  ไม่ตรงกับข้าพเจ้าเลย</p>
                 <p id="paddingfont">1  ตรงกับข้าพเจ้าบ้าง หรือเกิดขึ้นเป็นบางครั้ง</p>
                 <p id="paddingfont">2  ตรงกับข้าพเจ้า หรือเกิดขึ้นบ่อย</p>
                 <p id="paddingfont">3  ตรงกับข้าพเจ้ามาก หรือเกิดขึ้นบ่อยมากที่สุด</p>
                 </ModalBody>
               

                 <ModalFooter className="border-0"></ModalFooter>
                 </Modal>
            </div>
        );
    }
}
export default dassModals;