import React, { Component } from "react";
import { View } from 'react-native';
import Card from 'react-bootstrap/Card'
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  Input
} from "reactstrap";
import "../../../asset/css/test.css";
import { BsArrowRight } from "react-icons/bs";
import { ImInfo } from "react-icons/im";
import TMHQModals from "./modal/tmhqModal";
import { history } from "../../../history";
import Break from 'react-break';

class TMHQ extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: true,
      isModalRequiredOpen: false,
      questions: [
        {
          question: "ฉันมักกังวลกับสิ่งเล็กๆน้อยๆอยู่เสมอ",
          choice: 99,
        },
        {
          question: "ฉันมักจะตื่นเต้นง่ายกับสิ่งต่างๆ",
          choice: 99,
        },
        {
          question: "ฉันมักจะมีอาการประหม่าง่าย",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกกระวนกระวายตลอดเวลา",
          choice: 99,
        },
        {
          question: "ฉันมักจะวิตกกังวลไปล่วงหน้าโดยขาดสาเหตุที่แน่ชัด",
          choice: 99,
        },
        {
          question: "ฉันถูกรบกวนด้วยอาการคลื่นไส้อาเจียน",
          choice: 99,
        },
        {
          question: "หัวใจของฉันเต้นเร็วกว่าปกติโดยไม่ได้ทำกิจกรรมอะไร",
          choice: 99,
        },
        {
          question: "ฉันมีความลำบากในการหายใจ",
          choice: 99,
        },
        {
          question: "มือและเท้าของฉันอุ่นอยู่ตลอดเวลา",
          choice: 99,
        },
        {
          question: "มือของฉันสั่นอยู่เกือบตลอดเวลา",
          choice: 99,
        },
        {
          question: "ฉันมักจะกลัวสิ่งต่างๆโดยไม่มีสาเหตุ",
          choice: 99,
        },
        {
          question: "ฉันมักจะกลัวอย่างมากจนเกือบควบคุมตัวเองไม่ได้",
          choice: 99,
        },
        {
          question: "ฉันมักจะย้ำคิดกับเรื่องที่กังวลอยู่บ่อยๆ",
          choice: 99,
        },
        {
          question: "ฉันคิดว่าฉันรู้สึกสงบทั้งภายในและภายนอก",
          choice: 99,
        },
        {
          question: "ฉันสามารถที่จะออกไปข้างนอกได้โดยไม่มีความกังวล",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกเศร้า",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่มีสมาธิกับงานหรือสิ่งต่างๆที่ทำในชีวิตประจำวัน",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกหมดความสนใจกับงานอดิเรกที่เคยมี",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่สนุกกับสิ่งต่างๆเหมือนเช่นเคย",
          choice: 99,
        },
        {
          question: "น้ำหนักของฉันลดลงประมาณ 1-2 กิโลกรัมในช่วง 1 เดือนที่ผ่านมาโดยที่ฉันไม่ได้พยายามคุมน้ำหนักหรือมีการเจ็บป่วยทางร่างกาย",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกเบื่อหน่ายและท้อแท้",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกเชื่องช้าและเซื่องซึมไม่อยากทำอะไร",
          choice: 99,
        },
        {
          question: "การนอนของฉันผิดปกติและรบกวนฉัน",
          choice: 99,
        },
        {
          question: "ฉันใช้เวลามากกว่าเดิมในการนอนตอนกลางคืน",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกหมดกำลังใจ",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่มีค่าและละอายใจตนเอง",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกผิดเกือบตลอดเวลา",
          choice: 99,
        },
        {
          question: "ฉันกำลังถูกลงโทษจากการกระทำที่ไม่ดีของตนเอง",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกด้อยเมื่อเปรียบเทียบกับคนอื่น",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกว่าชีวิตทุกวันนี้ของฉันไม่มีประโยชน์",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่มีความสุขเลย",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกสิ้นหวัง",
          choice: 99,
        },
        {
          question: "อนาคตของฉันมืดมน",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกว่าชีวิตไม่มีค่าควรแก่การอยู่",
          choice: 99,
        },
        {
          question: "ชีวิตไม่มีอะไรน่าสนใจอีกต่อไปแล้ว",
          choice: 99,
        },
      ],
    };
  }

  toggle = () => {
    this.state.isModalOpen
      ? this.setState({ isModalOpen: false })
      : this.setState({ isModalOpen: true });
  };

  toggleRequired = () => {
    this.state.isModalRequiredOpen
      ? this.setState({ isModalRequiredOpen: false })
      : this.setState({ isModalRequiredOpen: true });
  };

  _handleSubmit = () => {
    let isEmpty = false;
    this.state.questions.map((q, i) => {
      if (q.choice == 99) {
        isEmpty = true;
      }
    });
    if (isEmpty) {
      this.toggleRequired();
    } else {
      // update this one on localStorage too
      localStorage.setItem("tmhq", JSON.stringify(this.state.questions));

      // save to db
      const db = this.props.database

      let u_name = localStorage.getItem('name')
      let u_name_no_space = u_name.split(' ').join('_')

      const dass = JSON.parse(localStorage.getItem('dass'))
      const tmhq = JSON.parse(localStorage.getItem('tmhq'))

      let dassRawScore = []
      let tmhqRawScore = []

      dass.map((q, index)=>{
        dassRawScore.push(q.choice)
      })
      tmhq.map((q, index)=>{
        tmhqRawScore.push(q.choice)
      })

      console.log(dassRawScore, tmhqRawScore);

      db.ref('/users').child(u_name_no_space).update({
        name: u_name,
        dass: dassRawScore,
        tmhq: tmhqRawScore,
        isWebDone: true
      }).then(()=>{
        console.log('db updated');
        localStorage.clear()
        localStorage.setItem('name', u_name)
        history.push("/status?d=web");
      }).catch(err=>console.log(err))
    }
  };

  _handleChoice = (i, c) => {
    let choice = c;
    let index = i;

    this.setState(
      ({ questions }) => ({
        questions: [
          ...questions.slice(0, index),
          {
            ...questions[index],
            choice: choice,
          },
          ...questions.slice(index + 1),
        ],
      }),
      () => console.log(this.state)
    );
  };

  render() {
    const UIBreakpoints = {
      mobile: 0,
      phablet: 550,
      tablet: 768,
      desktop: 1200,
    };

    const header = { }

    return (
      <div>
      <Modal
        isOpen={this.state.isModalRequiredOpen}
        toggle={this.toggleRequired}
      >
        <ModalBody className="text-center">
          กรุณาทำแบบทดสอบให้ครบทุกข้อ
        </ModalBody>
      </Modal>
        <TMHQModals isOpen={this.state.isModalOpen} toggle={this.toggle} />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col className="text-right mx-4">
            <ImInfo className="mt-3" size={20} onClick={this.toggle} />
          </Col>
        </Row>
        <h4>แบบประเมินสุขภาพจิตในคนไทย (Thai Mental Health Questionnaire)</h4>
        <Container id="pad" className="justify-content-md-center">
            <Card id="cardtest">
            <Card.Body>
            <Card.Text>
            กรุณาอ่านข้อความแต่ละข้อความแล้วประเมินว่าในรอบ 1 เดือนที่ผ่านมา ท่านมีลักษณะหรืออาการในแต่ละข้อความมากน้อยเพียงใด
            </Card.Text>
            </Card.Body>
            </Card>
            </Container>
        <Container>
        <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'desktop'}}>
          <div>
          <Row>
            <Col></Col>
            <Col>
              <Row id="font">
                <Col>ไม่มี</Col>
                <Col>เล็กน้อย</Col>
                <Col>ปานกลาง</Col>
                <Col>ค่อนข้างมาก</Col>
                <Col>มาก</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'tablet'}}>
          <div>
          <Row>
            <Col></Col>
            <Col>
              <Row id="font">
                <Col>ไม่มี</Col>
                <Col>เล็กน้อย</Col>
                <Col>ปานกลาง</Col>
                <Col>ค่อนข้างมาก</Col>
                <Col>มาก</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'mobile' }}>
          <div>
          <Row>
            <Col xs={5}></Col>
            <Col xs={6}>
              <Row id="font">
                <Col xs={2}>ไม่มี</Col>
                <Col xs={3}>เล็กน้อย</Col>
                <Col xs={3}>ปานกลาง</Col>
                <Col xs={2}>ค่อนข้างมาก</Col>
                <Col xs={1}>มาก</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'phablet'}}>
          <div>
          <Row>
            <Col xs={5}></Col>
            <Col xs={7}>
              <Row id="font">
              <Col xs={2}>ไม่มี</Col>
                <Col xs={3}>เล็กน้อย</Col>
                <Col xs={3}>ปานกลาง</Col>
                <Col xs={2}>ค่อนข้างมาก</Col>
                <Col xs={1}>มาก</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>

          {this.state.questions.map((question, index) => {
            if (index == 8 || index == 13 || index == 14) {
              // positive condition
              return (
                <Row className="mt-3">
                  <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                    {index + 1}
                    {". "}
                    {question.question}
                  </Col>
                  <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                    <Row>
                      <Col className="ml-3">
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 4)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 3)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 2)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 1)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 0)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row className="mt-3">
                  <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                    {index + 1}
                    {". "}
                    {question.question}
                  </Col>
                  <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                    <Row>
                      <Col className="ml-3">
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 0)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 1)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 2)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 3)}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="radio"
                          name={"c" + index}
                          onChange={(e) => this._handleChoice(index, 4)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }
          })}
          <Button id="fbutton" className="rounded-pill sent" onClick={this._handleSubmit}>
            ต่อไป <BsArrowRight size={24} />
          </Button>
        </Container>
      </div>
    );
  }
  }
export default TMHQ;
