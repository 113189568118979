import React, { useState } from 'react';
import Image from 'react-bootstrap/Image'
import {
    Navbar,
    NavbarBrand,
  } from 'reactstrap';
import '../../asset/css/nav.css'
class NavigationBar extends React.Component {
    render(){
        return(
            <div>
                 <Navbar color="light" light expand="md">
                     <a href="/"><Image src="/jubjai.png" width="50" height="50"/></a>
                    <a href="/"><NavbarBrand>PSYJAI</NavbarBrand></a>
                 </Navbar>
            </div>
        );
    }
}
export default NavigationBar;
