import React from 'react';
import Image from 'react-bootstrap/Image'
import "bootstrap/dist/css/bootstrap.css";
import '../../../App.css';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CardBody } from 'reactstrap';
import Break from 'react-break';
import { View } from 'react-native';


class App extends React.Component {
    render() {
      const UIBreakpoints = {
        mobile: 0,
        phablet: 550,
        tablet: 768,
        desktop: 1200,
      };
      return (
        <div>
      <Card id="box" body inverse style={{ backgroundColor: '#DCF1FF'}}>
      <Container id="container" >
      <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'desktop'}}>
          <div>
          <Row>
          <Col xs={6}>
          <Card.Img variant="left" src="/psy3.gif" width="600" height="338"/>
          </Col>
          <Col xs={6}>
            <Card.Body id="card">
            <h1>PSYJAI BY SIRIRAJ</h1>
            <h2>AI CHATBOT FOR MENTAL HEALTH CARE</h2>
          </Card.Body>
          </Col>
          </Row>
          </div>
      </Break>
      <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'tablet'}}>
          <div>
          <Row>
          <Col xs={6}>
          <Card.Img variant="left" src="/psy3.gif" width="370" height="338"/>
          </Col>
          <Col xs={6}>
            <Card.Body id="card">
            <h1>PSYJAI BY SIRIRAJ</h1>
            <h2>AI CHATBOT FOR MENTAL HEALTH CARE</h2>
          </Card.Body>
          </Col>
          </Row>
          </div>
      </Break>
      <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'mobile'}}>
          <div>
          <Row>
          <Col xs={6}>
          <Card.Img variant="left" src="/psy3.gif" width="170" height="338"/>
          </Col>
          <Col xs={6}>
            <Card.Body id="card">
            <p className="mobile">PSYJAI BY SIRIRAJ</p>
            <h2>AI CHATBOT FOR MENTAL HEALTH CARE</h2>
          </Card.Body>
          </Col>
          </Row>
          </div>
      </Break>
      </Container>
      </Card>
      <Card id="head">
        <CardBody variant="left">
          <p className="header">โครงการวิจัยเพื่อพัฒนาแชทบอทใส่ใจ (PSYJAI)</p>
          <p className="line">──────────</p>
        <p className="message">PsyJai (ใส่ใจ) หุ่นยนต์โต้ตอบอัตโนมัติ ผู้ช่วยเหลือเรื่องสุขภาพจิตแบบครบวงจร
          เริ่มตั้งแต่การประเมินภาวะสุขภาพจิตและให้การดูแลประคับประคองตามหลักจิตวิทยา</p>
        <p className="message">PsyJai เป็นความร่วมมือระหว่างภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ศิริราชพยาบาล และภาควิชาวิศวกรรมคอมพิวเตอร์
          คณะวิศวกรรมศาสตร์ มหาวิทยาลัยมหิดล โดยการสนับสนุนของ กองทุนวิจัยและพัฒนากิจการกระจายเสียง กิจการโทรทัศน์
          และกิจการโทรคมนาคมเพื่อประโยชน์สาธารณะ (กทปส) ที่ให้การส่งเสริมและสนับสนุนสถานพยาบาลของรัฐและโรงพยาบาลภาคสนามของรัฐ
          ต่อสู้สถานการณ์ “ไวรัสโคโรน่า” สายพันธุ์ใหม่ ๒๐๑๙ (COVID-19)</p>
        </CardBody>
        </Card>
        <Card id="head">
        <CardBody  variant="left">
          <p className="header">คำชี้แจง</p>
          <p className="line">──────────</p>
        <p className="message">เรียนผู้เข้าร่วมงานวิจัย</p>
        <p className="message">งานวิจัยนี้เป็นส่วนหนึ่งของการพัฒนา "ใส่ใจ (PSYJAI)" แชทบอทดูแลสุขภาพจิต ท่านได้รับเชิญให้เข้าร่วมงานวิจัยนี้เนื่องจากท่านเป็นผู้มีคุณสมบัติตามเกณฑ์ ได้แก่ ผู้ใช้บริการ Facebook ที่มีอายุ 18 ปีขึ้นไป ไม่จำกัดเพศ
        สามารถอ่านและเข้าใจภาษาไทยได้ และยินยอมเข้าร่วมงานวิจัย หากท่านยินดีเข้าร่วมงานวิจัย ท่านจะถูกขอให้เข้าสู่ระบบด้วย Facebook ของท่าน
        จากนั้นท่านจะต้องตอบแบบสอบถามเพื่อประเมินภาวะสุขภาพจิต  ประเมินสุขภาพจิตด้วยการพูดคุยกับระบบหุ่นยนต์โต้ตอบอัตโนมัติ (แชทบอท)
        โดยระบบจะสุ่มว่าท่านจะได้ตอบแบบสอบถาม หรือสนทนากับแชทบอทก่อน กระบวนการทั้งหมดจะใช้เวลาประมาณ 30-45 นาที โดยเมื่อท่านทำครบแล้ว
        ท่านจะได้ทราบผลการประเมินสุขภาพจิตของท่านทันที ข้อมูลทั้งหมด จะถูกเก็บเป็นความลับอย่างเคร่งครัด การรายงานผลการวิจัยจะเป็นไปในภาพรวม
        และไม่มีการเปิดเผยข้อมูลส่วนตัวของท่านโดยเด็ดขาด</p>
        <p className="message">ขอแสดงความนับถือ </p>
        <p className="message" >คณะผู้วิจัย</p>
        </CardBody>
      </Card>
      <Button id="button" href="/consent" size={30}>เริ่มทำแบบทดสอบ</Button>
      <Container id="space">
      <Row className="d-flex justify-content-center">
      <Col xs lg="4">
      <Image src="/siriraj.png" width="300" height="100" />
      </Col>
      <Col md="auto">
      <Image src="/eg.png" width="320" height="110" />
      </Col>
      <Col xs lg="2">
      <Image src="/unnamed.png" width="130" height="120"/>
      </Col>
      </Row>
      </Container>
      </div>
      )
    }
  }

  export default App;
