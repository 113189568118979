import React from 'react';
// import './App.css';
import{ BreakpointProvider } from 'react-socks';

import AppRouter from './Router'

function App() {
  return (
    <AppRouter/>
  );
}

export default App;
