import React from "react";
import {
  Card,
  CardColumns,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  CardGroup,
} from "react-bootstrap";
import "../../../asset/css/result.css";
import "semantic-ui-css/semantic.min.css";
import { Segment } from "semantic-ui-react";
import { BsArrowRight } from 'react-icons/bs'


class Result extends React.Component {

  componentDidMount() {

  }

  render() {
    return (
      <div>
        <h3 id="text1" class="d-flex justify-content-center">ผลคะแนน</h3>
        <h4 id="text2" class="d-flex justify-content-center">แบบประเมินภาวะสุขภาพจิต (Depression Anxiety Stress scale : DASS-21)</h4>
        <CardColumns id="space2">
          <Card id="card1" class="col col-lg-2">
            <Card.Body>
              <Card.Title>ความซึมเศร้า</Card.Title>
              <Card.Subtitle>(Depression)</Card.Subtitle>
              <Segment>
                <Card.Text>ความซึมเศร้าของคุณอยู่ในระดับ: {this.props.dassDLevel}</Card.Text>
              </Segment>
            </Card.Body>
          </Card>
          <Card id="card2" class="col-md-auto">
            <Card.Body>
              <Card.Title>ความวิตกกังวล</Card.Title>
              <Card.Subtitle>(Anxiety)</Card.Subtitle>
              <Segment>
                <Card.Text>ความวิตกกังวลของคุณอยู่ในระดับ: {this.props.dassALevel}</Card.Text>
              </Segment>
            </Card.Body>
          </Card>
          <Card id="card3" class="col col-lg-2">
            <Card.Body>
              <Card.Title>ความเครียด</Card.Title>
              <Card.Subtitle>(Stress)</Card.Subtitle>
              <Segment>
                <Card.Text>ความเครียดของคุณอยู่ในระดับ: {this.props.dassSLevel}</Card.Text>
              </Segment>
            </Card.Body>
          </Card>
        </CardColumns>
        <Button className="rounded-pill sent" onClick={this.props.onLogout}>ออกจากระบบ Facebook</Button>
        {' '}
        <Button href="/" className="rounded-pill sent">กลับสู่หน้าหลัก <BsArrowRight size={24}/></Button>
      </div>
    );
  }
}
export default Result;
