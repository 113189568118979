import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter ,Col, Row} from "reactstrap";
import '../../../../asset/css/test.css'
import { BiCircle } from 'react-icons/bi'


class tmhqModals extends React.Component{
    render(){
         console.log(this.props.isOpen)
        return(
            <div>
                 <Modal 
                 isOpen={this.props.isOpen} 
                 toggle={this.props.toggle}
                 className={this.props.className}
                 >
                <ModalHeader toggle={this.props.toggle} className="border-0" > 
                </ModalHeader>
                 <ModalBody className="mx-4 text-justify">
                 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>ข้อความต่อไปนี้เป็นข้อความ ที่ให้ท่านสำรวจสุขภาพจิตของท่านในช่วง 1 เดือนที่ผ่านมาว่าท่านมีอาการดังต่อไปนี้หรือไม่
                 โดยสำรวจระดับความมากน้อยของอาการที่ปรากฏ ตั้งแต่ระดับ ไม่มี เล็กน้อย ปานกลาง ค่อนข้างมาก และมาก โดยเลือกคะแนนจากช่อง <BiCircle/> ลงไปในช่องคะแนนที่ท่านต้องการ
                 </ModalBody>
                 <ModalBody className="mx-4 text-left">
                     <Row>
                     <Col className="text-right">ไม่มี หมายถึง</Col>
                     <Col>ท่านไม่เคยมีหรือไม่เคยรู้สึกเลยในตลอดช่วงระยะเวลา 1 เดือน</Col>
                     </Row>
                     <Row>
                     <Col className="text-right">เล็กน้อย หมายถึง</Col>
                     <Col>ท่านเคยมีหรือเคยรู้สึก คือประมาณครั้งหรือสองครั้งในช่วงเวลา 1 เดือน</Col>
                     </Row>
                     <Row>
                     <Col className="text-right">ปานกลาง หมายถึง</Col>
                     <Col>ท่านเคยมีหรือเคยรู้สึกพอประมาณ  คือประมาณ สัปดาห์ละครั้ง</Col>
                     </Row>
                     <Row>
                     <Col className="text-right">ค่อนข้างมาก หมายถึง</Col>
                     <Col>ท่านเคยมีหรือเคยรู้สึกหลายๆครั้งใน 1 สัปดาห์</Col>
                     </Row>
                     <Row>
                     <Col className="text-right">มาก หมายถึง</Col>
                     <Col>ท่านเคยมีหรือเคยรู้สึกบ่อยมาก คือเกือบทุกวัน</Col>
                     </Row>
                
                     </ModalBody>
                 <ModalFooter className="border-0"></ModalFooter>
                 </Modal>
            </div>
        );
    }
}
export default tmhqModals;