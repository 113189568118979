import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import { Button } from "reactstrap";
import "../../../asset/css/choice.css";
import { Segment, Grid } from "semantic-ui-react";
class Info extends React.Component {
  render() {
    return (
      <div>
        <h5 id="text1" class="d-flex justify-content-center">
          ข้อมูลการดูแลสุขภาพจิตเบื้องต้น
        </h5>
        <Grid divided="vertically">
          <Grid.Row>
            <Grid.Column>
              <h5 id="text" class="d-flex justify">
                ความซึมเศร้า
              </h5>
              <p id="text" class="text-left">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                อารมณ์เศร้า เป็นหนึ่งในอารมณ์พื้นฐานของมนุษย์ สามารถถูกกระตุ้นได้จากเหตุการณ์ที่เข้ามากระทบกับจิตใจ 
                เช่น ความผิดหวัง หรือความสูญเสีย  ซึ่งระดับของอารมณ์เศร้านั้น ขึ้นอยู่กับการตีความของแต่ละบุคคล 
                และสามารถดีขึ้นได้เมื่อเหตุการณ์ดังกล่าวผ่านไป เช่นเดียวกับอารมณ์อื่นๆ
              </p>
              <p id="text" class="text-left">
                สำหรับผู้ที่มีอารมณ์ซึมเศร้าในระดับที่สูง หรือเป็นอยู่นาน อาจมีลักษณะที่สามารถสังเกตเห็นได้ชัดใน 3 ด้านดังนี้
              </p>
              <p id="text" class="text-left">
                ด้านอารมณ์ : มักมีอารมณ์เศร้า หรือเบื่อหน่าย ท้อแท้  หรืออาจเป็นไปในลักษณะอารมณ์หงุดหงิด ฉุนเฉียวง่าย หรือมีอารมณ์โกรธ
              </p>
              <p id="text" class="text-left">
                ด้านพฤติกรรม : อาจแสดงออกเป็นอาการเซื่องซึม ขาดความกระตือรือร้น เบื่ออาหาร มีความผิดปกติด้านการนอน เป็นต้น
              </p>
              <p id="text" class="text-left">
                ด้านสังคม : อาจมีลักษณะแยกตัวจากผู้อื่น หรือมีปัญหาสัมพันธภาพกับผู้อื่นได้
              </p>
              <p id="text" class="text-left">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                หากท่านมีภาวะอารมณ์เศร้า สามารถดูแลตนเองเบื้องต้นได้ตามคำแนะนำดังต่อไปนี้
              </p>
              <p id="text" class="text-left">
                1) ดูแลร่างกาย - การพักผ่อนที่เพียงพอ รับประทานอาหารที่มีประโยชน์ การออกกำลังกาย 
                รวมถึงการผ่อนคลายความตึงเครียดของร่างกาย จะช่วยคืนความสดใสให้กับคนที่มีอารมณ์หม่นหมองได้
              </p>
              <p id="text" class="text-left">
                2) ดูแลจิตใจ - สามารถทำได้ในหลายๆระดับขึ้นอยู่กับความพร้อมของแต่ละคน อาจจะเริ่มจากการทำกิจกรรมที่ชื่นชอบ 
                ทำงานอดิเรกต่างๆ ไปจนถึงการค้นหาสาเหตุที่ทำให้เกิดความเศร้า และฝึกจัดการกับสิ่งนั้น 
                หรือมองหาสิ่งที่ตนเองพอจะจัดการได้ในสถานการณ์ที่น่าอึดอัด ก็จะสามารถลดความรู้สึกเศร้าลงได้
              </p>
              <p id="text" class="text-left">
                3) ดูแลความคิด - โดยทั่วไป คนที่มีความรู้สึกเชิงลบมักมีแนวโน้มที่จะรับรู้และตีความสถานการณ์ที่เข้ามากระทบจิตใจในเชิงลบ 
                ลองสังเกตความคิดของตนเอง ว่ามีผลกับความรู้สึกในตอนนั้นอย่างไร แล้วค่อยๆปรับมุมมองที่มีต่อสถานการณ์ 
                อาจเป็นการมองให้รอบด้านมากขึ้น หรือลองมองจากมุมอื่นๆดูบ้าง ก็อาจช่วยให้เข้าใจสิ่งต่างๆได้ดีขึ้น
              </p>
              <p id="text" class="text-left">
                หากไม่สามารถจัดการกับความเศร้าที่เกิดขึ้นได้ และรู้สึกว่าอารมณ์ดังกล่าวเริ่มมีผลกระทบต่อการดำเนินชีวิต 
                ท่านสามารถเข้ารับบริการจากผู้เชี่ยวชาญด้านสุขภาพจิตที่สถานพยาบาลใกล้บ้าน <a href="https://www.facebook.com/thaipsychiatricassociation/photos/a.499791366791551/2011213002316039/?type=3&theater" target="_blank">
                  รวมรายชื่อ โรงพยาบาล และ คลินิกที่มีจิตแพทย์ทั่วประเทศ
                </a>
              </p>
              <p id="text" class="text-left">
                หรือโทรสายด่วนสุขภาพจิต 1323 เพื่อขอคำปรึกษา
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h5 id="text" class="d-flex justify">
                ความวิตกกังวล
              </h5>
              <p id="text" class="text-left">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                ความวิตกกังวล คือ
                อารมณ์ที่เกิดขึ้นเมื่อบุคคลต้องเผชิญกับสถานการณ์ที่ก่อให้เกิดความตึงเครียดและไม่สามารถคาดเดาได้
                โดยมากความวิตกกังวลมักเกี่ยวข้องกับสิ่งที่จะเกิดขึ้นในอนาคต
                ทำให้บุคคลเกิดความคิดคาดการณ์ถึงสิ่งต่างๆซึ่งมักเป็นการคาดการณ์ไปในแง่ร้าย
              </p>
              <p id="text" class="text-left">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                ข้อดีของความวิตกกังวล คือ เป็นสิ่งกระตุ้น
                หรือตัวช่วยเตือนภัยให้บุคคลมีพฤติกรรมเตรียมรับมือกับสถานการณ์ต่างๆที่อาจจะเป็นอันตรายในอนาคต
                เช่น เตรียมร่มกันฝนตกในวันที่พยากรณ์อากาศรายงานว่าฝนจะตก
                ตั้งนาฬิกาปลุกล่วงหน้าเพื่อไม่ให้ตื่นไปเรียนสาย เป็นต้น
                ซึ่งจากตัวอย่างจะสังเกตได้ว่าภายใต้ความวิตตกกัวลนี้บุคคลจะใช้ชีวิตอย่างรอบคอบและวางแผนล่วงหน้ามากขึ้น
              </p>
              <p id="text" class="text-left">
                อย่างไรก็ตาม
                ความวิตกกังวลอยู่ในระดับที่สูงอาจส่งผลให้เกิดอาการต่างๆได้
                ดังนี้
              </p>
              <p id="text" class="text-left">
                ด้านจิตใจ : จะทำให้สับสน หลงลืมง่าย
                มีการรับรู้ที่บิดเบือนไปจากความเป็นจริง รบกวนสมาธิในการคิด
                การตัดสินใจ และการทำงานต่างๆ
              </p>
              <p id="text" class="text-left">
                ด้านร่างกาย : มักมีอาการเหงื่อออก ใจสั่น มือสั่น
                หายใจไม่ทั่วท้อง
              </p>
              <p id="text" class="text-left"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                อาการต่างๆเหล่านี้ หากท่านมีมากจนไม่สามารถควบคุมตนเองได้ และมีผลกระทบต่อการดำเนินชีวิตประจำวันทั่วไป ท่านสามารถเข้ารับบริการจากจิตแพทย์ได้ที่สถานพยาบาลใกล้เคียง
                ดังนี้ <a href="https://www.facebook.com/ThaiPsychiatricAssociation/photos/a.499791366791551/2011213002316039/?type=3&theater" target="_blank">
                  รวมรายชื่อ โรงพยาบาล และ คลินิกที่มีจิตแพทย์ทั่วประเทศ
                </a> หรือ <a href="https://news.thaipbs.or.th/content/278345" target="_blank">
                  https://news.thaipbs.or.th/content/278345
                </a>
              </p>
              <p id="text" class="text-left"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                แต่หากอาการดังกล่าวไม่ได้รบกวนการใช้ชีวิตประจำวัน
                ท่านสามารถเรียนรู้วิธีผ่อนคลายได้ด้วยตนเอง
                โดยเลือกได้ตามความสนใจและสามารถปรับใช้ให้เหมาะกับสถานการณ์หรือตามความเหมาะสม
                เช่น
              </p>
              <p id="text" class="text-left">
                1) ฝึกหายใจเพื่อผ่อนคลาย โดยหายใจช้า ๆ หายใจเข้าลึกๆ
                หายใจออกยาวๆ เนื่องจาก โดยทั่วไปแล้ว เมื่อเกิดอาการกังวล
                คนเรามักมีการหายใจที่เร็วกว่าปกติ
              </p>
              <p id="text" class="text-left">
                2) ฝึกผ่อนคลายกล้ามเนื้อ โดยใช้วิธีการเหยียดยืดกล้ามเนื้อ
                หรืออาจจะสะบัดร่างกายเบาๆ ให้คลายจากอาการเกร็ง
                เนื่องจากบุคคลที่เกิดความกังวลมักมีอาการเกร็งกล้ามเนื้อโดยไม่รู้ตัว
              </p>
              <p id="text" class="text-left">
                3) ฝึกอยู่กับปัจจุบัน อยู่กับสิ่งตรงหน้าที่เกิดขึ้น
                หรือกลับมาอยู่กับลมหายใจของตนเอง สังเกตการเข้าออกของลมหายใจ
                หรือการขยับที่หน้าท้องในขณะที่หายใจ
                ซึ่งวิธีนี้สามารถเรียกได้ว่าเป็นการฝึกสมาธิ
              </p>
              <p id="text" class="text-left">
                4) หรือใช้เวลาอยู่กับคนที่รัก ซึ่งอาจจะเป็นครอบครัว เพื่อน คนรัก
                หรือสัตว์เลี้ยง โดยไม่คิดถึงเหตุการณ์ที่ยังไม่เกิดขึ้น
              </p>
              <p id="text" class="text-left">
                5) การพยายามเผชิญหน้ากับสิ่งที่ตนเองกลัวหรือวิตกทีละน้อย
                อย่างช้า ๆ แทนการหลีกเลี่ยง
              </p>
              <p id="text" class="text-left">
                6) สำรวจ
                ตรวจสอบความคิดของตนเองว่าสิ่งที่วิตกกังวลนั้นสมเหตุผลสมผลหรือไม่
              </p>
              <p id="text" class="text-left">
                7) แบ่งเวลาให้ตนเองได้คิดถึงสิ่งที่กังวล และควรจำกัดเวลา
                อย่างเช่น วันละ 10 นาที
                เมื่อครบกำหนดแล้วควรเปลี่ยนไปทำกิจกรรมอื่นๆต่อ
              </p>
              <p id="text" class="text-left">
                8) ใช้การจดบันทึก เพื่อให้เห็นถึงสาเหตุของความกังวล
                และนำไปสู่การรับมือและจัดการได้อย่างถูกต้อง เหมาะสมต่อไป
              </p>
              <p id="text" class="text-left">
                9) เรียนรู้จากผู้อื่น
                โดยพูดคุยกับบุคคลที่มีปัญหาหรือความกังวลใจที่คล้ายกับตนเอง
                ถึงวิธีการจัดการและรับมือกับสิ่งต่างๆ
                เพื่อนำไปปรับใช้ให้เหมาะสมกับสถานการณ์ของตนเอง
              </p>
              <p id="text" class="text-left">
                <a href="https://www.creativethailand.org/article/howto/32016/Anti-Anxiety-Remedy-10#Anti-Anxiety-Remedy-10" target="_blank">
                  https://www.creativethailand.org/article/howto/32016/Anti-Anxiety-Remedy-10#Anti-Anxiety-Remedy-10
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <h5 id="text" class="d-flex justify">
                ความเครียด
              </h5>
              <p id="text" class="text-left">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                ความเครียดหมายถึงปฏิกิริยาของร่างกายและอารมณ์ที่ตอบสนองต่อสถานการณ์ใหม่ๆที่เกิดขึ้น
                การมีความเครียดในระดับที่เหมะสม เป็นปฏิกิริยาปกติ
                และเป็นสิ่งที่ควรมี
                เนื่องจากปฏิกิริยาดังกล่าวเป็นแรงจูงใจหรือผลักดันให้บุคคลเกิดพฤติกรรมการปรับตัวและแก้ไขปัญหาเพื่อที่จะดำเนินชีวิตต่อไปอย่างราบรื่น
                ซึ่งในทางตรงกันข้าม หากเกิดความเครียดในระดับที่สูงมากเกินไป
                ก็อาจเกิดผลเสียได้วิธีสังเกตเบื้องต้นว่ามีความเครียดในระดับสูงหรือไม่
                สามารถสังเกตได้ดังนี้
              </p>
              <p id="text" class="text-left">
                ด้านร่างกาย : นอนไม่หลับ ฝันร้าย เบื่ออาหาร ใจสั่น ปัสสาวะบ่อย
                ปวดหัว ปวดเมื่อยตามร่างกาย อ่อนเพลีย หายใจไม่อิ่ม
              </p>
              <p id="text" class="text-left">
                ด้านจิตใจ : วิตกกังวล กลัว เบื่อหน่าย กระวนกระวาย กระสับกระส่าย
                หงุดหงิดง่าย สมาธิไม่ดี ขี้ลืม คิดหมกมุ่น
              </p>
              <p id="text" class="text-left">
                ซึ่งท่านสามารถจัดการความเครียดได้ด้วยตนเอง ด้วยวิธีการต่างๆ เช่น
              </p>
              <p id="text" class="text-left">
                1) ออกกำลังกายเป็นประจำ หรือฝึกโยคะ
              </p>
              <p id="text" class="text-left">
                2) หาสิ่งที่ชอบ ทำงานอดิเรก เช่น ฟังเพลง ดูหนัง ปลูกต้นไม้
                ทำอาหาร ทำงานศิลปะ
              </p>
              <p id="text" class="text-left">
                3) หาที่ปรึกษาพูดคุย ระบายเรื่องที่ค้างคาในใจ
              </p>
              <p id="text" class="text-left">
                4) หาเวลาให้กับตนเอง ชื่นชมธรรมชาติหรือสิ่งรอบตัว ทำสมาธิ
                ผ่อนคลายร่างกาย อารมณ์ จิตใจของตนเอง เช่น
                บางคนที่เครียดเกี่ยวกับเรื่องงาน
                ภาระงานที่มากเกินไปควรลองจัดสรรเวลาให้กับตัวเองดู
                เพื่อเพิ่มพื้นที่ความสุขให้กับตนเอง
              </p>
              <p id="text" class="text-left">
                5) ฝึกผ่อนคลายเทคนิคการผ่อนคลาย เช่น{" "}
                <a href="https://youtu.be/3IB6E7B6-EI" target="_blank">
                  https://youtu.be/3IB6E7B6-EI
                </a>
              </p>
              <p id="text" class="text-left">
                ทั้งนี้ หากท่านรู้สึกว่าไม่สามารถจัดการความเครียดได้
                หรือความเครียดส่งผลต่อการดำเนินชีวิต
                ท่านสามารถเข้ารับบริการจากจิตแพทย์ได้ที่สถานพยาบาลใกล้เคียง
                ดังนี้
              </p>
              <p id="text" class="text-left">
                <a
                  href="https://www.facebook.com/ThaiPsychiatricAssociation/photos/a.499791366791551/2011213002316039/?type=3&theater"
                  target="_blank"
                >
                  รวมรายชื่อ โรงพยาบาล และ คลินิกที่มีจิตแพทย์ทั่วประเทศ
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
export default Info;
