import React, { Component } from "react";
import {
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Card from 'react-bootstrap/Card'
import "../../../asset/css/test.css";
import { BsArrowRight } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImInfo } from "react-icons/im";
import DASSModals from "./modal/dassModal";
import { history } from "../../../history";
import Break from 'react-break';

class DASS extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: true,
      isModalRequiredOpen: false,
      questions: [
        {
          question: "ฉันรู้สึกยากที่จะสงบจิตใจลง",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกปากแห้งคอแห้ง",
          choice: 99,
        },
        {
          question: "ฉันแทบไม่รู้สึกอะไรดีๆ เลย",
          choice: 99,
        },
        {
          question: "ฉันมีอาการหายใจผิดปกติ (เช่น หายใจเร็วเกินเหตุ หายใจไม่ทันแม้ว่าจะไม่ได้ออกแรง)",
          choice: 99,
        },
        {
          question: "ฉันพบว่ามันยากที่จะคิดริเริ่มทำสิ่งใดสิ่งหนึ่ง",
          choice: 99,
        },
        {
          question: "ฉันมีแนวโน้มที่จะตอบสนองเกินเหตุต่อสถานการณ์",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกว่าร่างกายบางส่วนสั่นผิดปกติ (เช่น มือสั่น)",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกเสียพลังงานไปมากกับการคิดกังวล",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกกังวลกับเหตุการณ์ที่อาจทำให้ฉันรู้สึกตื่นกลัวและกระทำบางสิ่งที่น่าอับอาย",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่มีเป้าหมายในชีวิต",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกกระวนกระวายใจ",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกยากที่จะผ่อนคลายตัวเอง",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกจิตใจเหงาหงอยเศร้าซึม",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกทนไม่ได้เวลามีอะไรมาขัดขวางสิ่งที่ฉันกำลังทำอยู่",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกคล้ายจะมีอาการตื่นตระหนก",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกไม่มีความกระตือรือร้นต่อสิ่งใด",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกเป็นคนไม่มีคุณค่า",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกค่อนข้างฉุนเฉียวง่าย",
          choice: 99,
        },
        {
          question: "ฉันรับรู้ถึงการทำงานของหัวใจแม้ในตอนที่ฉันไม่ได้ออกแรง (เช่นรู้สึกว่าหัวใจเต้นเร็วขึ้นหรือเต้นไม่เป็นจังหวะ)",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกกลัวโดยไม่มีเหตุผล",
          choice: 99,
        },
        {
          question: "ฉันรู้สึกว่าชีวิตไม่มีความหมาย",
          choice: 99,
        },
      ],
    };
  }

  toggle = () => {
    this.state.isModalOpen
      ? this.setState({ isModalOpen: false })
      : this.setState({ isModalOpen: true });
  };

  toggleRequired = () => {
    this.state.isModalRequiredOpen
      ? this.setState({ isModalRequiredOpen: false })
      : this.setState({ isModalRequiredOpen: true });
  };

  _handleSubmit = () => {
    let isEmpty = false;
    this.state.questions.map((q, i) => {
      if (q.choice == 99) {
        isEmpty = true;
      }
    });
    if (isEmpty) {
      this.toggleRequired();
    } else {
      localStorage.setItem("dass", JSON.stringify(this.state.questions));
      history.push("/tmhq/");
    }
  };

  _handleChoice = (i, c) => {
    let choice = c;
    let index = i;

    this.setState(
      ({ questions }) => ({
        questions: [
          ...questions.slice(0, index),
          {
            ...questions[index],
            choice: choice,
          },
          ...questions.slice(index + 1),
        ],
      }),
      () => console.log(this.state)
    );
  };

  render() {
      const UIBreakpoints = {
        mobile: 0,
        phablet: 550,
        tablet: 768,
        desktop: 1200,
      };
    return (
      <div>
        <Modal
          isOpen={this.state.isModalRequiredOpen}
          toggle={this.toggleRequired}
        >
          <ModalBody className="text-center">
            กรุณาทำแบบทดสอบให้ครบทุกข้อ
          </ModalBody>
        </Modal>
        <DASSModals isOpen={this.state.isModalOpen} toggle={this.toggle} />
        <Row>
          <Col></Col>
          <Col></Col>
          <Col className="text-right mx-4">
            <ImInfo className="mt-3" size={20} onClick={this.toggle} />
          </Col>
        </Row>
        <h4>แบบประเมินภาวะซึมเศร้า วิตกกังวล และความเครียด (DASS-21)</h4>
        <Container id="pad" className="justify-content-md-center">
            <Card id="cardtest">
            <Card.Body>
            <Card.Text>
            กรุณาอ่านข้อความต่อไปนี้แล้วประเมินว่าข้อความแต่ละข้อตรงกับท่านเพียงใดในรอบ 2 สัปดาห์ที่ผ่านมา
            </Card.Text>
            </Card.Body>
            </Card>
            </Container>
        <Container>
        <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'desktop'}}>
          <div>
          <Row>
            <Col></Col>
            <Col>
              <Row id="font">
                <Col>ไม่ตรงกับข้าพเจ้า</Col>
                <Col>ตรงกับข้าพเจ้าบ้าง หรือเกิดขึ้นเป็นบางครั้ง</Col>
                <Col>ตรงกับข้าพเจ้า หรือเกิดขึ้นบ่อย</Col>
                <Col>ตรงกับข้าพเจ้ามาก หรือเกิดขึ้นบ่อยมากที่สุด</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'phablet'}}>
          <div>
          <Row>
            <Col xs={5}></Col>
            <Col xs={7}>
              <Row id="fontmobile">
                <Col xs={3}>ไม่ตรงกับข้าพเจ้า</Col>
                <Col xs={3}>ตรงกับข้าพเจ้าบ้างหรือเกิดขึ้นเป็นบางครั้ง</Col>
                <Col xs={3}>ตรงกับข้าพเจ้าหรือเกิดขึ้นบ่อย</Col>
                <Col xs={2}>ตรงกับข้าพเจ้ามากหรือเกิดขึ้นบ่อยมากที่สุด</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'tablet'}}>
          <div>
          <Row>
            <Col></Col>
            <Col>
              <Row id="font">
                <Col>ไม่ตรงกับข้าพเจ้า</Col>
                <Col>ตรงกับข้าพเจ้าบ้าง หรือเกิดขึ้นเป็นบางครั้ง</Col>
                <Col>ตรงกับข้าพเจ้า หรือเกิดขึ้นบ่อย</Col>
                <Col>ตรงกับข้าพเจ้ามาก หรือเกิดขึ้นบ่อยมากที่สุด</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          <Break
          breakpoints={UIBreakpoints}
          query={{ method: 'is', breakpoint: 'mobile'}}>
          <div>
          <Row>
            <Col xs={5}></Col>
            <Col xs={6}>
              <Row id="fontmobile">
                <Col xs={3}>ไม่ตรงกับข้าพเจ้า</Col>
                <Col xs={3}>ตรงกับข้าพเจ้าบ้างหรือเกิดขึ้นเป็นบางครั้ง</Col>
                <Col xs={3}>ตรงกับข้าพเจ้าหรือเกิดขึ้นบ่อย</Col>
                <Col xs={3}>ตรงกับข้าพเจ้ามากหรือเกิดขึ้นบ่อยมากที่สุด</Col>
              </Row>
            </Col>
          </Row>
          </div>
          </Break>
          {this.state.questions.map((question, index) => {
            return (
              <Row className="mt-3">
                <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                  {index + 1}
                  {". "}
                  {question.question}
                </Col>
                <Col className={index % 2 == 0 ? "ques" : "ques2"}>
                  <Row>
                    <Col className="ml-3">
                      <Input
                        type="radio"
                        name={"c" + index}
                        onChange={(e) => this._handleChoice(index, 0)}
                      />
                    </Col>
                    <Col>
                      <Input
                        type="radio"
                        name={"c" + index}
                        onChange={(e) => this._handleChoice(index, 1)}
                      />
                    </Col>
                    <Col>
                      <Input
                        type="radio"
                        name={"c" + index}
                        onChange={(e) => this._handleChoice(index, 2)}
                      />
                    </Col>
                    <Col>
                      <Input
                        type="radio"
                        name={"c" + index}
                        onChange={(e) => this._handleChoice(index, 3)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Button id="fbutton" className="rounded-pill sent" onClick={this._handleSubmit}>
            ต่อไป <BsArrowRight size={24} />
          </Button>
        </Container>
      </div>
    );
  }
}
export default DASS;
